import React from "react";

import { ConfigCenterProvider } from "./ConfigCenterProvider";

export function withConfigCenter<T = any>(Component: React.FC<T>): React.FC<T> {
  return (props) => {
    return (
      <ConfigCenterProvider>
        <Component {...props} />
      </ConfigCenterProvider>
    );
  };
}
