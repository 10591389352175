import React from "react";

import { HashRouter as Router } from "react-router-dom";

import { RouteUrls } from "routing/routing.constants";

export function withRouter<T = any>(Component: React.FC<T>): React.FC<T> {
  return (props) => {
    return (
      <Router basename={RouteUrls.ROOT_URL} hashType="noslash">
        <Component {...props} />
      </Router>
    );
  };
}
