import React, {useCallback, useState} from "react";

import {useHistory} from "react-router-dom";

import {PageType} from "shared/constants/PageType";

import {RouteUrls} from "routing/routing.constants";

import {FormFieldsModel} from "pages/ContactUs/models";

import {ConfigCenterContext} from "./ConfigCenterContext";

const pagesRoutesMap = new Map<PageType, RouteUrls>([
  [PageType.MAIN, RouteUrls.ROOT_URL],
  [PageType.CONTACT_US, RouteUrls.CONTACT_US_URL],
  [PageType.PRIVACY_POLICY, RouteUrls.PRIVACY_POLICY_URL],
  [PageType.TERMS_OF_USE, RouteUrls.TERMS_OF_USE_URL],
  [PageType.OUR_PROCESS, RouteUrls.OUR_PROCESS_URL],
  [PageType.COOKIE_POLICY, RouteUrls.COOKIE_POLICY_URL],
  [PageType.GAME_WHITE_PAPER, RouteUrls.GAME_WHITE_PAPER_URL],
  [PageType.NFT_STACKING, RouteUrls.NFT_STACKING_URL],
  [PageType.GAME_RULES, RouteUrls.GAME_RULES_URL],
  [PageType.MEET_THE_TEAM, RouteUrls.MEET_THE_TEAM_URL],
  [PageType.GAME_FAQ, RouteUrls.GAME_FAQ_URL],
    [PageType.BUY_NFT, RouteUrls.BUY_NFT_URL]
]);

export const ConfigCenterProvider: React.FC = React.memo(({ children }) => {
  const history = useHistory();
  const [visibleMenuPanel, setVisibleMenuPanel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState<FormFieldsModel>();

  const [activePage, setActivePage] = useState(PageType.MAIN);

  const toggleMenuPanel = useCallback(() => {
    setVisibleMenuPanel((prev) => !prev);
  }, []);

  const changePage = useCallback(
    (page: PageType, scrollCb?: () => void) => {
      history.push(pagesRoutesMap.get(page) ?? RouteUrls.ROOT_URL);
      setVisibleMenuPanel(false);
      if (scrollCb) {
        setTimeout(() => scrollCb(), 100);
      } else {
        window.scroll({ top: 0, behavior: "smooth" });
      }
    },
    [history]
  );

  return (
    <ConfigCenterContext.Provider
      value={[
        { visibleMenuPanel, activePage, isLoading, formValues },
        { toggleMenuPanel, changePage, setIsLoading, setFormValues },
      ]}
    >
      {children}
    </ConfigCenterContext.Provider>
  );
});
