import React from "react";

import cn from "classnames";
import { useLocation } from "react-router-dom";

import { useConfigCenter } from "core/context/ConfigCenter";
import { MenuButton } from "shared/components/MenuButton";
import { PageType } from "shared/constants/PageType";
import { RouteUrls } from "routing/routing.constants";

import "./Header.scss";

export const Header: React.FC = React.memo(() => {
  const [, { toggleMenuPanel, changePage }] = useConfigCenter();
  const location = useLocation();

  const isMainPage = location.pathname === RouteUrls.ROOT_URL;

  return (
    <div className="header">
      <div className="header__title">
        <div
          className={cn(["ooyoot-logo", !isMainPage && "link"])}
          onClick={() => !isMainPage && changePage(PageType.MAIN)}
        />
        <div className="header__title_name">
          <span>presents:</span>
          <div
            className={cn(["game-name", !isMainPage && "link"])}
            onClick={() => !isMainPage && changePage(PageType.MAIN)}
          />
        </div>
      </div>
      <div className="header__menu" onClick={toggleMenuPanel}>
        <MenuButton />
      </div>
    </div>
  );
});
