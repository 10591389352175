import React from "react";

import { Spin } from "antd";

import Icon from "@ant-design/icons";
import { ReactComponent as LoaderSvg } from "assets/icons/icon-loader.svg";

import { LoaderProps } from "./LoaderProps.model";

import "./Loader.scss";

const loaderIcon = <Icon className="loader-icon" component={() => <LoaderSvg className="loader-svg" />} />;

export const Loader: React.FC<LoaderProps> = ({ wrapperClassName, isLoading, children }) => {
  return (
    <Spin
      data-testid="loader"
      spinning={isLoading}
      indicator={loaderIcon}
      style={{ height: "100%", backgroundColor: "transparent", maxHeight: "inherit" }}
      wrapperClassName={wrapperClassName}
      size="large"
    >
      {children}
    </Spin>
  );
};
