import React from "react";

import "./Button.scss";

interface ButtonProps<T = any> {
  onClick?: (data: T) => void | Promise<void>;
  className?: string;
  disabled?: boolean;
  type?: "submit" | "button" | "reset";
  mode?: "danger" | "grey";
  size?: "small" | "normal";
}

export const Button: React.FC<ButtonProps> = React.memo(
  ({ children, onClick, className, disabled, type, mode, size }) => {
    return (
      <div className={["button", className, disabled && "disabled", mode, size].filter(Boolean).join(" ")}>
        {children}
        <button type={type} className="button__top" onClick={onClick} disabled={disabled}>
          {children}
        </button>
        <div className="button__back">{children}</div>
      </div>
    );
  }
);
