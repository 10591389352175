import React, { Suspense } from "react";

import { Route, Redirect, Switch } from "react-router-dom";

import { Loader } from "shared/components/Loader";

import { routes, RouteUrls } from "./routing.constants";

export const Roulette: React.FC = React.memo(() => {
  return (
    <Suspense fallback={<Loader isLoading={true} />}>
      <Switch>
        {routes.map((routeProps, index) => {
          return <Route {...routeProps} key={index} />;
        })}
        <Redirect to={RouteUrls.BUY_NFT_URL} />
      </Switch>
    </Suspense>
  );
});
