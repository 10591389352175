import React from "react";

import { Header } from "core/components/Header";
import { Footer } from "core/components/Footer";
import { withConfigCenter, useConfigCenter } from "core/context/ConfigCenter";

import { PageLayout } from "shared/components/PageLayout";

import { Roulette, withRouter } from "routing";

import { MenuPanel } from "../components/MenuPanel";

import "shared/styles/app.scss";

export const App: React.FC = withRouter(
  withConfigCenter(
    React.memo(() => {
      const [{ visibleMenuPanel, activePage }, { toggleMenuPanel, changePage }] = useConfigCenter();

      return (
        <div className="App">
          <PageLayout
            header={<Header />}
            footer={<Footer />}
            menu={<MenuPanel onClose={toggleMenuPanel} visible={visibleMenuPanel} changePage={changePage} />}
          >
            <Roulette />
          </PageLayout>
        </div>
      );
    })
  )
);
