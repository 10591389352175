export enum PageType {
  MAIN = "MAIN",
  COOKIE_POLICY = "COOKIE_POLICY",
  GAME_WHITE_PAPER = "GAME_WHITE_PAPER",
  OUR_PROCESS = "OUR_PROCESS",
  TERMS_OF_USE = "TERMS_OF_USE",
  PRIVACY_POLICY = "PRIVACY_POLICY",
  CONTACT_US = "CONTACT_US",
  NFT_STACKING = "NFT_STACKING",
  GAME_RULES = "GAME_RULES",
  MEET_THE_TEAM = "MEET_THE_TEAM",
  GAME_FAQ = "GAME_FAQ",
  BUY_NFT = "BUY_NFT",
}
