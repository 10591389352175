import React from "react";

import Icon from "@ant-design/icons";
import { BackTop } from "antd";

import { ReactComponent as TopArrow } from "assets/icons/top-arrow.svg";

import { Button } from "../Button";

import "./PageLayout.scss";

interface PageLayoutProps {
  header: React.ReactNode;
  footer: React.ReactNode;
  menu?: React.ReactNode;
}

export const PageLayout: React.FC<PageLayoutProps> = React.memo(({ children, footer, header, menu }) => {
  return (
    <div className="page-layout">
      <header className="page-layout__header">{header}</header>
      {!!menu && menu}
      {children}
      <BackTop>
        <Button>
          <Icon className="top-arrow" component={TopArrow} />
        </Button>
      </BackTop>
      <footer className="page-layout__footer">{footer}</footer>
    </div>
  );
});
