import React from "react";

import { UnderlinedTitle } from "shared/components/UnderlinedTitle";
import { TextPageLayout } from "shared/components/TextPageLayout";

import "./CookiePolicy.scss";

export const CookiePolicy: React.FC = React.memo(() => {
  return (
    <TextPageLayout title="Cookie policy">
      <div className="cookie-policy">
        <div>
          <div className="cookie-policy__subtitle">
            <UnderlinedTitle>general</UnderlinedTitle>
          </div>
          <div>
            This cookie policy is based on the "Directive on privacy and
            electronic communications" (Directive 2002/58/EC of the European
            Parliament and of the Council of 12 July 2002 concerning the
            processing of personal data and the protection of privacy in the
            electronic communications sector (Directive on privacy and
            electronic communications).
            <br />
            <br />
            This directive contains the rules regarding information about and
            consents to cookies that are saved on your computer. When you visit
            this website information about you, your computer and your behavior
            on the site is collected. In this cookie policy, you can read in
            detailed information about how we handle the collected information,
            what we use it for, who has access to the information and whom you
            can contact regarding the collected information.
            <br />
            <br />
            By visiting and using this website, you give your consent to this
            cookie policy and the collection of the information about you.
          </div>
        </div>
        <div className="cookie-policy__second">
          <div className="cookie-policy__subtitle">
            <UnderlinedTitle>cookies</UnderlinedTitle>
          </div>
          <div>
            <div>
              When you visit this website, you automatically receive one or more
              cookies.
            </div>
            <div className="cookie-policy__info">
              <div className="cookie-policy__topic">What is a cookie?</div>
              <div>
                A cookie is a small text file stored in your browser and
                recognised by the website upon returning, enabling us to send
                personalised information to your browser. A cookie can contain
                text, numbers or e.g. a date, but there is no personal
                information stored in a cookie. It is not a program and cannot
                contain a virus.
              </div>
            </div>
            <div className="cookie-policy__info">
              <div className="cookie-policy__topic">
                How long do we store the cookies?
              </div>
              <div>
                The cookies sent to you from this website are stored for maximum
                24 months from your latest visit. Every time you revisit the
                site, the period is prolonged. The cookies concerned will,
                therefore, be stored in a maximum of 24 months counting from
                your last visit to our website.
              </div>
            </div>
            <div className="cookie-policy__info">
              <div className="cookie-policy__topic">
                How do I avoid cookies?
              </div>
              <div>
                Rejecting cookies: You can always reject cookies on your
                computer by changing the settings in your browser. How you find
                these settings depends on your browser. You must be aware that
                if you choose to reject cookies, there might be functions you
                cannot use because these functions and services presuppose that
                the website remembers the choices you make.
                <br />
                <br />
                Deleting cookies: Cookies that you previously accepted can
                afterwards easily be deleted. If you use a PC with a recent
                Internet browser you can delete your cookies using the keyboard
                shortcut: CTRL + SHIFT + Delete. If the shortcut does not work,
                or if you use a MAC, you may start by finding out which browser
                you use and subsequently clicking the relevant link.
                <br />
                <br />
                Remember: If you use several Internet browsers you have to
                delete the cookies in all of them.
              </div>
            </div>
            <div className="cookie-policy__info">
              <div className="cookie-policy__topic">
                What are cookies used for on our website?
              </div>
              <div>
                Cookies are necessary for the website to work properly.
                Furthermore, cookies help us gain an overview of your visit to
                the site so that we can optimise and target the website
                continually to your needs and interests. Cookies remember e.g.
                what you added to a potential basket if you previously visited
                the site and what language and currency you prefer on the site.
                We use cookies to determine who visits our website as well as
                keeping statistics, analyses and re-marketing. On a general
                level, the cookies are thus utilised as part of our service to
                show you content as relevant as possible.
              </div>
            </div>
            <div className="cookie-policy__info">
              <div className="cookie-policy__topic">
                Cookies on neuroborncats.com
              </div>
              <div>
                Neuroborncats.com is hosted on a Tier 3 Data Center. Your data
                is kept safe on a secure server behind a firewall. The platform
                registers cookies, which are essential for the function of this
                website.
              </div>
            </div>
          </div>
        </div>
      </div>
    </TextPageLayout>
  );
});
