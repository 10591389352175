import React from "react";

import { PageType } from "shared/constants/PageType";

import { ConfigCenterActions, ConfigCenterState } from "./models";

export const ConfigCenterContext = React.createContext<[ConfigCenterState, ConfigCenterActions]>([
  { visibleMenuPanel: false, activePage: PageType.MAIN, isLoading: false },
  {
    toggleMenuPanel: () => {},
    changePage: () => {},
    setIsLoading: () => {},
    setFormValues: () => {},
  },
]);
