import { lazy } from "react";

import { RouteProps } from "react-router-dom";

import CookiePolicy from "pages/CookiePolicy";

const Main = lazy(() => import("pages/Main"));
const GameWhitePaper = lazy(() => import("pages/GameWhitePaper"));
const OurProcess = lazy(() => import("pages/OurProcess"));
const TermsOfUse = lazy(() => import("pages/TermsOfUse"));
const PrivacyPolicy = lazy(() => import("pages/PrivacyPolicy"));
const ContactUs = lazy(() => import("pages/ContactUs"));
const NftStacking = lazy(() => import("pages/NFTsIngameStacking"));
const GameRules = lazy(() => import("pages/GameRules"));
const MeetTheTeam = lazy(() => import("pages/MeetTheTeam"));
const GameFAQ = lazy(() => import("pages/GameFAQ"));
const BuyNft = lazy(() => import("pages/BuyNft"));

export enum RouteUrls {
  ROOT_URL = "/",
  OUR_PROCESS_URL = "/our-process",
  GAME_WHITE_PAPER_URL = "/game-white-paper",
  MEET_THE_TEAM_URL = "/meet-the-team",
  BLOG_URL = "/blog",
  CONTACT_US_URL = "/contact-us",
  COOKIE_POLICY_URL = "/cookie-policy",
  PRIVACY_POLICY_URL = "/privacy-policy",
  TERMS_OF_USE_URL = "/terms-of-use",
  GAME_RULES_URL = "/game-rules",
  NFT_STACKING_URL = "/nft-stacking",
  GAME_FAQ_URL = "/game-faq",
  BUY_NFT_URL = "/buy-nft",
}

export const routes: RouteProps[] = [
  {
    component: Main,
    path: RouteUrls.ROOT_URL,
    exact: true,
  },
  {
    component: CookiePolicy,
    path: RouteUrls.COOKIE_POLICY_URL,
    exact: true,
  },
  {
    component: ContactUs,
    path: RouteUrls.CONTACT_US_URL,
    exact: true,
  },
  {
    component: PrivacyPolicy,
    path: RouteUrls.PRIVACY_POLICY_URL,
    exact: true,
  },
  {
    component: TermsOfUse,
    path: RouteUrls.TERMS_OF_USE_URL,
    exact: true,
  },
  {
    component: OurProcess,
    path: RouteUrls.OUR_PROCESS_URL,
    exact: true,
  },
  {
    component: GameWhitePaper,
    path: RouteUrls.GAME_WHITE_PAPER_URL,
    exact: true,
  },
  {
    component: NftStacking,
    path: RouteUrls.NFT_STACKING_URL,
    exact: true,
  },
  {
    component: GameRules,
    path: RouteUrls.GAME_RULES_URL,
    exact: true,
  },
  {
    component: MeetTheTeam,
    path: RouteUrls.MEET_THE_TEAM_URL,
    exact: true,
  },
  {
    component: GameFAQ,
    path: RouteUrls.GAME_FAQ_URL,
    exact: true,
  },
  {
    component: BuyNft,
    path: RouteUrls.BUY_NFT_URL,
    exact: true,
  }
];
