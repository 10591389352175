import React, { useCallback } from "react";

import Icon from "@ant-design/icons";

import { useConfigCenter } from "core/context/ConfigCenter";

import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { PageType } from "shared/constants/PageType";

import "./MenuPanel.scss";

interface MenuPanelProps {
  visible: boolean;
  onClose: () => void;
  changePage: (page: PageType) => void;
}

export const MenuPanel: React.FC<MenuPanelProps> = React.memo(({ onClose, visible }) => {
  const [, { changePage }] = useConfigCenter();

  const scrollCb = useCallback(() => {
    const aboutTheGameWrapper = document.querySelector(".about-game-part");
    aboutTheGameWrapper?.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <section className={["menu-panel", visible && "open"].filter(Boolean).join(" ")}>
      <Icon className="close-button" component={CloseIcon} onClick={onClose} />
      <div className="menu-panel__list">
        <div className="menu-panel__list_item" onClick={() => changePage(PageType.MAIN, scrollCb)}>
          the game
        </div>
        <div className="menu-panel__list_item" onClick={() => changePage(PageType.NFT_STACKING)}>
          NFT Staking
        </div>
        <div className="menu-panel__list_item" onClick={() => changePage(PageType.BUY_NFT)}>
          buy nft
        </div>
        <div className="menu-panel__list_item" onClick={() => changePage(PageType.GAME_FAQ)}>
          game faq
        </div>
        <div className="menu-panel__list_item" onClick={() => changePage(PageType.OUR_PROCESS)}>
          our process
        </div>
        <div className="menu-panel__list_item" onClick={() => changePage(PageType.GAME_WHITE_PAPER)}>
          game white paper
        </div>
        <div className="menu-panel__list_item" onClick={() => changePage(PageType.MEET_THE_TEAM)}>
          meet the team
        </div>
        <div className="menu-panel__list_item" onClick={() => changePage(PageType.CONTACT_US)}>
          contact us
        </div>
      </div>
    </section>
  );
});
