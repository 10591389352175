import React from "react";

import cn from "classnames";

import { PageTitle } from "shared/components/PageLayout";

import "./TextPageLayout.scss";

export const TextPageLayout: React.FC<{ title: string; className?: string }> = React.memo(
  ({ children, title, className }) => {
    return (
      <div className={cn("text-page-layout", className)}>
        <div className="text-page-layout__header">
          <PageTitle title={title} />
        </div>
        <div className="text-page-layout__body">
          <div className="text-page-layout__body_content">{children}</div>
        </div>
      </div>
    );
  }
);
