import React from "react";

import "./PageTitle.scss";

interface PageTitleProps {
  title: string;
}

export const PageTitle: React.FC<PageTitleProps> = React.memo(({ title }) => {
  return (
    <div className="page-title">
      {title}
      <div className="page-title__text" data-text={title}>
        {title}
      </div>
    </div>
  );
});
