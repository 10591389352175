import React from "react";

import "./MenuButton.scss";

export const MenuButton: React.FC = React.memo(() => {
  return (
    <div className="menu-button">
      <span className="menu-button__lines">
        <span className="menu-button__lines_line line-1" />
        <span className="menu-button__lines_line line-2" />
        <span className="menu-button__lines_line line-3" />
      </span>
    </div>
  );
});
