import React from "react";

import Icon from "@ant-design/icons";

import { useConfigCenter } from "core/context/ConfigCenter";

import { ReactComponent as Twitter } from "assets/icons/twitter.svg";

// import { ReactComponent as Instagram } from "assets/icons/instagram.svg";
import { ReactComponent as Telegram } from "assets/icons/telegram.svg";
import { ReactComponent as Discord } from "assets/icons/discord.svg";
import { ReactComponent as Medium } from "assets/icons/medium.svg";
import { ReactComponent as Mastodon } from "assets/icons/mastodon.svg";
import { ReactComponent as Age } from "assets/icons/age18.svg";

import { PageType } from "shared/constants/PageType";

import "./Footer.scss";

export const Footer: React.FC = React.memo(() => {
  const [, { changePage }] = useConfigCenter();

  return (
    <div className="footer">
      <a href="https://gatialabs.com/" rel="noreferrer" target="_blank">
        <div className="ooyoot-logo" />
      </a>
      <div className="game-name footer__title" />
      <div className="footer__links">
        <span onClick={() => changePage(PageType.COOKIE_POLICY)}>
          Cookie policy
        </span>
        <span onClick={() => changePage(PageType.PRIVACY_POLICY)}>
          Privacy policy
        </span>
        <span onClick={() => changePage(PageType.TERMS_OF_USE)}>
          Terms of use
        </span>
        <span onClick={() => changePage(PageType.GAME_RULES)}>Game rules</span>
      </div>
      <div className="footer__social">
        <a
          href="https://twitter.com/Neuroborncats"
          rel="noreferrer"
          target="_blank"
        >
          <Icon className="twitter-icon" component={Twitter} />
        </a>
        <a
          href="https://gatialabs.medium.com"
          rel="noreferrer"
          target="_blank"
        >
          <Icon className="medium-icon" component={Medium} />
        </a>
        {/*<a href="https://www.instagram.com/gatialabs/" rel="noreferrer" target="_blank">*/}
        {/*  <Icon className="instagram-icon" component={Instagram} />*/}
        {/*</a>*/}

        <a href="https://t.me/neuroborncats" rel="noreferrer" target="_blank">
          <Icon className="telegram-icon" component={Telegram} />
        </a>

        <a rel="me" href="https://mastodon.social/@gatialabs" target="_blank">
          <Icon className="mastodon-icon" component={Mastodon} />
        </a>

        <a
          href="https://discord.gg/MgV32NTpAH"
          rel="noreferrer"
          target="_blank"
        >
          <Icon className="discord-icon" component={Discord} />
        </a>
      </div>
      <div className="footer__info">
        <span>NEUROBORN CATS: THE GAME</span>
        <span>®/TM & © GATIA LABS ALL RIGHTS RESERVED.</span>
      </div>
      <div className="footer__age">
          <Icon className="footer__age-image" component={Age}/>
      </div>
    </div>
  );
});
